@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
    margin: 0;
    padding: 0;
}

:root {
    --bg-color: #f4f5f5;
    --primary-color: #0D7E5D;
    --color-link: #9898a0;
    --txt_sub: #777575;
    --fade-primary: rgba(13, 126, 93, 0.2);
}

body {
    font-family: "Poppins", sans-serif !important;
    text-decoration: none;
    background-color: var(--bg-color);
}

a {
    color: var(--color-link);
    text-decoration: none;
}

.contenedorMain {
    margin: 0 auto;
    display: grid;
    height: 100vh;

    grid-template-rows: 1fr 84px;
    grid-template-areas:
        "contenido"
        "nav";
}

.contenedorMain main {
    grid-area: contenido;
    padding: 0 25px 0 25px;
}

.contenedorMain .contenedorBottomNav {
    grid-area: nav;
}

@media only screen and (min-width: 750px) {
    .contenedorMain {
        grid-template-areas:
            "nav contenido";
        grid-template-columns: 210px 1fr;
        grid-template-rows: 1fr;
    }
}