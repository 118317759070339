.containerBottomNav {
    display: flex;
    justify-content: space-between;
    padding: 13px 25px 25px 25px;
    border-top: 1px solid var(--txt_sub);
    position: sticky;
    background-color: var(--bg-color);
    bottom: 0;
}

.iconGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconGroup img {
    margin: auto;
    width: 24px;
    height: 24px;
}

.iconGroup p {
    color: var(--txt_sub);
    font-size: 12px;
}

.link-active {
    color: var(--primary-color) !important;
}

@media only screen and (min-width: 750px) {
    .containerBottomNav {
        border-right: 1px solid var(--txt_sub);
        flex-direction: column;
        justify-content: center;
    }

    .iconGroup {
        flex-direction: row;
        margin: 20px auto;
        justify-content: start;
    }

    .iconGroup img {
        margin: 0 20px;
    }

    .iconGroup p {
        font-size: 16px;
    }
}