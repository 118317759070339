.presentacion {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
}

.presentacion .txts {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.presentacion .txts>p:nth-child(1),
.presentacion .txts h1 {
    font-size: 20px;
    margin-bottom: 1px;
    font-weight: bold;
}

.presentacion .txts>p:nth-child(2) {
    font-size: 15px;
    color: var(--txt_sub);
}

.img_perfil p {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    padding: 20px;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.btn_float img {
    background-color: var(--primary-color);
    padding: 18px;
    border-radius: 50%;
}

.btn_float {
    position: fixed !important;
    right: 25px !important;
    bottom: 110px !important;
}

/* modal */
.modal {
    bottom: 0 !important;
    top: unset !important;
}

.modal .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    margin: 0 !important;
    width: 100%;
    border-radius: 20px 20px 0 0 !important;
}

.modal form {
    display: flex;
    flex-direction: column;
}

.modal .btn {
    margin-top: 45px;
}

.modal .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 24px !important;
}

.btn_eliminar {
    background-color: #f60000 !important;
}

.btn_pagar {
    margin-bottom: -20px !important;
}

.btn_noPagado {
    margin-bottom: -20px !important;
    color: #F3A334 !important;
    border: 1px solid #F3A334 !important;
}

/* carpetas */
.containerCarpetas {
    margin-top: 35px;
}

.carpeta {
    display: flex;
    background-color: var(--fade-primary);
    border-radius: 10px;
    padding: 18px 20px;
    align-items: center;
    margin-top: 15px;
}

.carpeta img {
    width: 31px;
    height: 29px;
    margin-right: 15px;
}

.carpeta p {
    font-weight: bold;
    font-size: 14px;
    color: #000 !important;
}

.noHayCarpetas {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    justify-content: center;
}

.noHayCarpetas p {
    margin-top: 20px;
    font-size: 16px;
    color: var(--txt_sub);
    font-weight: bold;
}

/* registros */
.totalRegistro {
    background-color: var(--primary-color);
    color: #fff;
    padding: 6px 25px;
}

.totalRegistro>p:nth-child(1) {
    font-size: 16px;
}

.totalRegistro>p:nth-child(2) {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    margin-top: 37px;
    margin-bottom: 66px;
}

.totalMeta {
    background-color: rgba(12, 126, 93, 0.5);
    color: #fff;
    padding: 6px 25px;
}

.totalMeta .txt_p {
    color: #000 !important;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px;
}

.totalMeta .txt_p_2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-top: 13px;
    margin-bottom: 8px;
}

.AppBar {
    position: sticky;
    top: 0;
    background-color: var(--bg-color);
    display: flex;
    justify-content: space-between;
    padding: 30px 5px 10px 11px;
    align-items: center;
}

.AppBar p {
    font-weight: bold;
    color: #000;
    font-size: 14px;
}

.registro {
    border-bottom: 1px solid var(--txt_sub);
    display: flex;
}

.registro img {
    margin-left: 25px;
    margin-right: 40px;
}

.registro_txts {
    display: flex;
    flex-direction: column;
}

.registro_txts>p:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-top: 14px;
}

.registro_txts:nth-child(2) {
    font-size: 12px;
    color: var(--txt_sub);
    margin-bottom: 8px;
}

.registro:hover {
    background-color: #d3d0d0 !important;
    cursor: pointer;
}

.btn_float_registros img {
    background-color: var(--primary-color);
    padding: 18px;
    border-radius: 50%;
}

.btn_float_registros {
    position: fixed !important;
    right: 25px !important;
    bottom: 35px !important;
}

.btn_float_graficos img {
    background-color: #fff;
    border: 2px solid var(--primary-color);
    padding: 18px;
    border-radius: 50%;
}

.btn_float_graficos {
    position: fixed !important;
    right: 25px !important;
    bottom: 115px !important;
}

/* graficos */
.AppBar_graficos {
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: var(--bg-color);
    display: flex;
    justify-content: space-between;
    padding: 30px 5px 10px 11px;
    align-items: center;
    border-bottom: 1px solid var(--txt_sub);
}

.AppBar_graficos p {
    font-weight: bold;
    color: #000;
    font-size: 14px;
    margin: auto;
}

.containerGraficos {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    align-items: center;
}

.graficos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.graficos>div {
    margin: 20px;
}

.graficos>div:nth-child(1) {
    display: flex;
    justify-content: center;
}

.datosGraficos {
    margin-top: 25px;
    display: flex;
}

.datosGraficos>div {
    width: 100px;
}

.datosGraficos>div:nth-child(1) {
    margin-right: 20px;
}

.datosGraficos>div:nth-child(2) {
    margin-right: 20px;
    width: 130px;
}

/* cuenta */
.containerCuenta .AppBar {
    justify-content: end;
}

.datosUser {
    display: flex;
    justify-content: space-around;
}

.datos_group {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    width: 108px;
    height: 87px;
    align-items: center;
    justify-content: center;
}

.datos_group>p:nth-child(1) {
    color: var(--primary-color);
    margin-bottom: 14px;
    font-weight: bold;
}

.infoUser {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoUser>p:nth-child(1) {
    font-size: 36px;
    color: #fff;
    background-color: var(--primary-color);
    padding: 26px 28px;
    border-radius: 50%;
    font-weight: bold;
    margin-bottom: 20px;
}

.infoUser>p:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
}

.infoUser>p:nth-child(3) {
    font-size: 15px;
    color: var(--txt_sub);
}

.userForm form {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr;
}

.mainUser {
    display: grid;
    height: 80vh;
    align-items: center;
}

@media only screen and (max-width: 400px) {
    .grafico_largo {
        overflow-x: scroll;
        max-width: 320px;
        overflow-y: hidden;
    }
}

@media only screen and (min-width: 600px) {
    .css-1ssa5bp-MuiSnackbar-root {
        bottom: 190px !important;
        left: auto !important;
        right: 23px !important;
    }

    .modal {
        top: 0 !important;
    }

    .modal .css-tlc64q-MuiPaper-root-MuiDialog-paper {
        border-radius: 20px !important;
        width: 400px;
    }

    .AppBar {
        border-bottom: 1px solid var(--txt_sub);
    }

    .AppBar,
    .AppBar_graficos {
        padding: 10px 5px 10px 11px;
    }

    .AppBarCuenta {
        border: unset !important;
    }

    .totalRegistro {
        margin: 10px 20% 0 20%;
        border-radius: 10px;
    }

    .registros {
        margin: 0 20%;
    }

    .registro {
        border: 1px solid var(--txt_sub);
        margin: 10px 0;
        border-radius: 10px;
    }

    .btn_float {
        bottom: 25px !important;
    }

    .containerCarpetas {
        margin: 35px 20% 0 20%;
    }

    .mainUser {
        margin: 0 20% !important;
    }

    .userForm {
        margin: 20px 10% 0 10%;
    }
}