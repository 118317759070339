.containerLogin {
    height: 94vh;

    display: grid;
    padding: 25px;
    padding-bottom: 0;
}

.form {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr;
}

.logo {
    margin: 5rem auto 2.5rem auto;
    width: 93px;
    height: 93px;
}

.logoSignup {
    margin: 4rem auto 2.5rem auto;
    width: 93px;
    height: 93px;
}

.containerLogin h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 3px;
}

.txt_sub {
    font-size: 15px;
    color: var(--txt_sub);
    margin-bottom: 24px;
}

.link_olvide {
    color: var(--primary-color);
    font-size: 15px;
    text-align: end;
    margin-top: 7px;
    margin-bottom: 35px;
}

.div_go_other {
    margin-top: 4.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.div_go_other>p:nth-child(1) {
    color: var(--txt_sub);
}

.div_go_other>p:nth-child(2) {
    margin-left: 5px;
    color: var(--primary-color);
}

.btn {
    border-radius: 10px !important;
    font-family: "Poppins", sans-serif !important;
    font-variant: normal !important;
    text-transform: none !important;
    padding: 10px 0 10px 0 !important;
    font-size: 20px !important;
}

.containerLogin .btn_submit,
.containerLogin .btn_loading {
    margin-bottom: 66px !important;
}

.btn_google {
    display: flex;
    justify-content: center;
}

.containerSignup {
    height: 94vh;

    display: grid;
    padding: 25px;
    padding-bottom: 0;
}

.containerSignup h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 3px;
}

@media only screen and (min-width: 750px) {

    .containerLogin,
    .containerSignup {
        width: 400px;
        margin: auto;
    }

    .logo {
        margin: 0 auto 2.5rem auto;
    }

    .logoSignup {
        margin-top: 0;
    }

    .div_go_other {
        margin-top: 30px;
    }
}